import {CognitoUserPool, CognitoUser, AuthenticationDetails} from "amazon-cognito-identity-js"
import { getUserStore } from "Store/StoreConfig";
import { UserStore } from "Store/UserStore"
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const userPoolData = {
    UserPoolId: "us-west-1_9opAtUljW",
    ClientId: "4o4g6r17a1o7g7t166uogcdqnm"
}

export const userPool : CognitoUserPool = new CognitoUserPool(userPoolData)

export const createCognitoUser = (username: string) => {
    var userData = {
        Username: username,
        Pool: userPool,
    }
    return new CognitoUser(userData)
}


export const createAuthDetails = (email : string, password : string) => {
    return new AuthenticationDetails({
        Username:email,
        Password: password
    })
}


export const getSession = () => {
    const userStore : UserStore = getUserStore()
    const user : CognitoUser = userPool.getCurrentUser()
    if (user) {
        user.getSession(async (err, session) => {
            if (!err) {
                loadUser(user, userStore, session.getAccessToken()['jwtToken'])
            } else {
                userStore.setIsLoaded()
            }
        })
    } else {
        userStore.setIsLoaded()
    }
}


export const loadUser = (user : CognitoUser, UserStore : UserStore, accessToken : string) => {
    return new Promise((resolve, reject) => {
        return user.getUserAttributes(function(err, result) {
            if (err) {
                UserStore.setIsLoaded()
                reject(false)
            } else {
                setTokenCookie(accessToken)
                UserStore.setUser(user,result)
                UserStore.setIsLoaded()
                resolve(true)
            }
        })
    })              
}


export const handleUnauthorizedRequest = async () => {
    const userStore = getUserStore()
    const user : CognitoUser = userStore.cognitoUser
    if (user) {
        user.signOut(() => {
            userStore.clearUser()
            document.location.href="/";
        })
    }
}


export const setTokenCookie = (token) => {
    cookies.set("token", token, { path: '/' });
}


export const removeTokenCookie = () => {
    cookies.remove("token");
}


export const getAuthHeader = () => {
    return {Authorization: 'Bearer ' + cookies.get("token") };
 }


 export const getAuthHeaderWithContentJson = () => {
    return {Authorization: 'Bearer ' + cookies.get("token"), 
            "Content-Type": "multipart/form-data"};
 }



